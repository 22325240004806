import React from 'react'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../utils/linkResolver'
import { Link, graphql } from 'gatsby';
import s from "./index.module.scss"
import Layout from '../components/layouts'

export const query = graphql`
{
  prismic{
    allHomepages {
      edges {
        node {
          title
          subtitle
        }
      }
    }
    allCategoriess(sortBy: menu_order_ASC) {
      edges {
        node {
          title
          description
          image
          background_color
          _meta {
            uid
          }
        }
      }
    }
  }
}
`

const RenderMasterhead = ({ homepage }) => (
  <section className={s.masterhead}>
      <h1>{RichText.asText(homepage.title, linkResolver)}</h1>
      <p className={`light-text`}>{RichText.asText(homepage.subtitle, linkResolver)}</p>
    </section>
);

const RenderCategories = ({ categories }) => (
  <section>
    {categories.map(category => <div className={s.category} style={{ backgroundColor: category.background_color }} key={category._meta.uid}>
      <div className={s.categoryDetails}>
        <h2>{RichText.asText(category.title)}</h2>
        <p>{RichText.asText(category.description)}</p>
        <Link className={`btn btn-outline-dark`} to={category._meta.uid}>View all prints</Link>
      </div>
      <img src={category.image.url} alt={`Category: ${category.title}`} />
    </div>)}
  </section>
)

export default ({ data }) => {
  const homepage = data.prismic.allHomepages.edges.slice(0,1).pop();
  const categories = data.prismic.allCategoriess.edges.map(e => e.node);
  if(!homepage || !categories) return null;

  console.log(categories)
  
  return(
    <Layout>
      <RenderMasterhead homepage={homepage.node} />
      <RenderCategories categories={categories} />
    </Layout>
  )

}

